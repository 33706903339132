html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-size: 11pt;
    background: #e4e4e4;
}

a {
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

.wrapper {
    margin: 0 auto;
    padding: 0px 10px 5px 10px;
    max-width: 1164px;
}

/* .contentDescription[data-pint-1321="setToNone"] {
    display: none;
} */

.pointer {
    cursor: pointer;
}

.show {
    display: block;
}

.hidden {
    display: none;
}

/* Homepage header */
h1.title {
    display: inline;
    font-size: 20px;
    color: #606060;
    margin: 0;
    box-shadow: none;
    font-weight: bold;
}

span.bintro {
    font-size: 20px;
    color: #606060;
    font-weight: bold;
}

/* Content Description */
.banner-first,
.banner-second,
.banner-first img,
.banner-second img {
    width: 240px;
    height: 240px;
}

.banner-first {
    float: left;
    margin: 10px 20px 5px 0;
}

.banner-second {
    float: right;
    margin: 7px 0px 20px 20px;
}

@media only screen and (min-width: 240px) and (max-width: 400px) {
    .banner-first,
    .banner-second,
    .banner-first img,
    .banner-second img {
        width: 100px;
        height: 100px;
    }
}

.pquote-right,
.pquote-left {
    width: 320px;
    background: #f4f4f4 url(https://assets.myfavsexcams.xxx/images/openquote.svg) left center
        no-repeat;
    background-size: 100px 100px;
    background-position: 5% 50%;
    font-weight: bold;
    padding: 13px;
    margin-left: 10px;
    border-radius: 5px;
}

@media only screen and (min-width: 240px) and (max-width: 400px) {
    .pquote-right,
    .pquote-left {
        width: 100%;
        margin: 15px 0;
    }
}

blockquote {
    margin: 0;
}

.pquote-right blockquote p,
.pquote-left blockquote p {
    color: #2b5573;
    font-style: italic;
}

.pquote-right {
    float: right;
    margin: 15px 0 15px 20px;
}

.pquote-left {
    float: left;
    margin: 15px 20px 15px 0;
}

.links-icon {
    width: 16px;
    height: 16px;
    top: 2px;
    margin-right: 4px;
    margin-left: 2px;
    position: relative;
    display: inline-block;
}

ul {
    line-height: 24px;
}

li a {
    color: #e06939;
}

::marker {
    color: #e06939;
}

/******* email signup **********/

.email-campaigner-container {
    width: 100%;
}

.campaigner-form {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 2px;
    position: relative;
    height: 56px;
}

.form-email {
    height: 100%;
    width: 100%;
    box-shadow: none;
    font-size: 14px;
    padding: 9px 10px;
    border: 0;
    background: #f5f5f5;
    font-family: 'Montserrat', sans-serif;
    padding: 0 10px 0 32px;
    background-position: 10px center;
    background-repeat: no-repeat;
    background-size: 15px;
    background-image: url(https://assets.myfavsexcams.xxx/images/newsletter_icon_gray.jpg);
    height: 30px;
    width: 173px;
}

.btn-new-btn {
    height: 100%;
    width: 100%;
    transition: background-color 0.25s ease;
    text-transform: uppercase;
    background-color: #e06939;
    color: #fff;
    cursor: pointer;
    border: 0;
    letter-spacing: 1px;
    height: 30px;
    width: 173px;
}

.exs-alert-danger {
    text-align: center;
    font-size: 12px;
    color: red;
}

.exs-alert-success {
    color: #32b44a;
    text-transform: uppercase;
    text-align: center;
}

.msg-alert {
    margin-bottom: 10px;
    font-size: 12px;
}
.sectionTitle {
    margin-bottom: 30px;
    text-align: center !important;
    border-bottom: 1px solid #e06938;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    padding-bottom: 20px;
}

.inftab-content p {
    font-size: 16px;
    text-align: center;
    margin-right: 10px;
    line-height: 25px;
}
.inftab-content p a {
    color: #e06939 !important;
    text-decoration: none !important;
}

.inftab-content.okButton {
    color: rgb(249, 249, 249);
    border: 2px solid rgb(224, 105, 57);
    background-color: rgb(224, 105, 57);
    width: 68px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inftab-content.okButton:hover {
    background-color: rgb(254, 135, 87);
}

.inftab-content.hide {
    display: none;
}

.inftab-content h2 {
    margin-top: 20px;
    text-align: center;
}
